import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import 'bulma/css/bulma.css'

import Type from './components/Type'
import Survey from './components/Survey'
import Question from './components/Question'
import OpenQuestion from './components/OpenQuestion'
import AppLayout from './components/AppLayout'
import Loading from './components/Loading'
import UnsupportedBrowserMessage from './components/UnsupportedBrowserMessage';
import Config from './config'
import queryString from 'query-string'

import { HOST_PREFIX, HOST_SUFFIX, NEW_HOST_URL, STAGING_HOST_URL, CONFIGS_URL, DEVICES } from './constants';

import { detect } from 'detect-browser';
import ThankYouSection from './components/ThankYouSection';

function useLSCache(item, expiration){
    const cached = maybeParseJSON(localStorage.getItem(item)) || {};
    const setCache = useMemo(() => (value) => {
        localStorage.setItem(item, JSON.stringify(value));
    }, [item]);
    if (cached.timestamp){
        let diff = (new Date() - Date.parse(cached.timestamp)) / 1000;
        if (diff > expiration) {
            localStorage.removeItem(item);
            return [null, setCache];
        }
    }
    return [cached, setCache];
}

function useConfig(institution){
    const [config, setConfig] = useState({});
    const [loadingConfig, setLoadingConfig] = useState(true);

    useEffect(() => {
        if(institution) {
            setLoadingConfig(true);
            fetch(`${CONFIGS_URL}/${institution}.json`).then(response => response.json()).then(config => {
                console.log('config', config);
                setConfig(config);
                setLoadingConfig(false);
            });
            // =========== Comment fetch above and uncomment the following below to use a local configuration from config.js ========
            // console.log("LOCAL CONFIG: ", Config)
            // setConfig(Config.orientalbank);
            // setLoadingConfig(false);
            // ======================================================================================================================
        }
    }, [institution]);

    return [config, loadingConfig];
}

async function verifyTicket(domain, ticket_id){

    if(ticket_id) {
        let url = `${HOST_PREFIX}${domain}${HOST_SUFFIX}api/survey/ticketAnswered/${ticket_id}/`
        let result = false
        await fetch(url).then(response => response.json()).then(data => {
            result = data.submitted;
            return result;
        }).catch(error => {return false});
        return result;
    }
    else{
        return false;
    }
}

function App(props){
    const {
        config,
        state,
        type,
        setState,
        setValue,
        setValues,
        openButton,
        didAlreadyAnswer,
        browser,
        loadingConfig,
        hideLanguageBar,
        languages,
        urlData
    } = useAppHooks(props);

    function renderThankYouSection(help_url){
        return <ThankYouSection language={state.language} config={config} help_url={help_url} />
    }

    function renderSurvey(system, labels){
        var label_list = config.labels.map((l) => l[state.language])
        return <Survey system={system} labels={label_list} select={setValue}/>
    }

    function renderTypes(types){
        return <Type options={types} language={state.language} select={setValues}/>
    }

    function renderOpenQuestion(options){
        let btnText = openButton[state.language] || "Submit"
        return <OpenQuestion name="openAnswer" select={setValue} buttonText={btnText}/>
    }

    function renderQuestion(options, questionType){
        let option_list = options.map((op) => { return {id : op.id, text: op.option[state.language]} })
        return <Question options={option_list} select={setValue} questionType={questionType}/>
    }

    const getNavbarTitle = (config, language) => {
        if (state.service){
            if (config.navbarTitle){
                return {
                    bold: config.navbarTitle.bold[language],
                    normal: state.service,
                }
            }
            return {
                bold: '',
                normal: state.service,
            }
        }
        return {
            bold: "",
            normal: "",
        }
    }

    function determineRoute(){
        let route = null, prompt = null, navbarTitle = null;
        const noAutobanco = ["54", "55", "56", "57", "58",
            "59", "60", "61", "62", "63", "64", "65", "66",
            "73", "89", "90", "101", "103", "104", "105",
            "115", "118", "119", "120"
        ]
        const {
            domain,
            language,
            openAnswer,
            reason,
            rating,
            sending,
            teller,
            typeId,
            type_selected,
            help_url,
            ticketAnsweredState,
            extraQuestion
        } = state;

        const questionsArray = Object.entries(config.questions);
        const filteredQuestions = questionsArray.filter(([key, value]) =>{
            if (key === 'Auto Banco' && noAutobanco.includes(teller)){
                return false
            }
            else if (key === 'ATM' && teller < 121){
                return false
            }
            else {
                return true
            }
        });
        // if ((domain == null || teller == null) || didAlreadyAnswer || ticketAnsweredState !== false){ //!== false para exceptuar el undefined cuando no esta cargado aun
        if ((domain == null || teller == null) || didAlreadyAnswer){ // Se comento lo de ticketAnswered por error al llamar esa data
            route = renderThankYouSection(help_url);
        } else if(sending){
            prompt = <button className="button loading is-loading is-large">Loading</button>;
            route = null;
        } else if (typeId == null){
            prompt = config.typeText[language];
            route = renderTypes(Object.fromEntries(filteredQuestions));
        } else if (rating == null){
            prompt = config.prompt[language];
            route = renderSurvey(config.system, config.labels);
        } else if (reason == null){
            if(typeId){
                let type_from_url = Object.values(config.questions).filter(obj => { 
                    return obj.typeId === parseInt(typeId);
                })[0] || Object.values(config.questions)[0] 
                prompt = type_from_url.title[language];
                navbarTitle = getNavbarTitle(config, language);
                route = renderQuestion(type_from_url["options"], "reason");
            }
            else{
                prompt = type_selected.title[language];
                navbarTitle = getNavbarTitle(config, language);
                route = renderQuestion(type_selected["options"], "reason");
            }
        } 
        else if(extraQuestion == null && config.extraQuestion){
            prompt= config.extraQuestion.title[language];
            navbarTitle = getNavbarTitle(config, language);
            route = renderQuestion(config.extraQuestion.options, "extraQuestion");
        }
        else if (openAnswer == null && config.openAnswer){
            prompt= config.openQuestionPrompt[language];
            navbarTitle = getNavbarTitle(config, language)
            route = renderOpenQuestion();
        } 
        else {
            route = renderThankYouSection(help_url);
        }

        return [route, prompt, navbarTitle];
    }

    if (browser.name === "ie" && parseInt(browser.version) < 9){
        return <UnsupportedBrowserMessage />;
    }

    const [route, prompt, navbarTitle] = loadingConfig ? [] : determineRoute();

    return loadingConfig ? (
        <Loading />
    ) : (
        <AppLayout
            css={config.css}
            hideLanguageBar={hideLanguageBar}
            languages={languages}
            setLanguage={language => setState({ language })}
            title={prompt}
            navbarTitle={navbarTitle}
        >{route}</AppLayout>
    );
}


function useAppHooks(props){
    const urlData = useMemo(() => ({
      ...queryString.parse(window.location.search),
      ...queryString.parse(window.location.hash)
    }), [window.location.hash, window.location.search]);
    const browser = useMemo(() => detect(), []);
    let ticketAnswered = false;

    let expirationSeconds = 1800;  // Default
    if(urlData.i === "evertec"){
        expirationSeconds = 1
    }
    else if(urlData.i === "orientalbank"){
        if(urlData.d in DEVICES){
            urlData.t = 10
        }
    }
    const [latest, setLatest] = useLSCache('latestSubmit', expirationSeconds);
    const didAlreadyAnswer = !!(
        latest
        && latest.rating
        && latest.reason !== null && latest.reason !== undefined
        && (latest.openAnswer || latest.openAnswer === "")
    );
    const [config, loadingConfig] = useConfig(urlData.i);
    const [lastUpdate, setLastUpdate] = useState();
    const languages = config.languages || {"en": "English", "es": "Español"};
    const openButton = {"en": "Submit", "es": "Contestar"};
    let language = urlData.lng || 'es';

    if (!languages[language]) {
      language = Object.keys(languages)[0];
    }

    const { questions, system } = config;

    const type = questions && questions[Object.keys(questions)[0]];
    const [state, _setState] = useState({
        domain: urlData?.i || null,
        ticket: urlData?.ticket || null,
        typeId: urlData?.t || null,
        teller: urlData?.d || null,
        service: urlData?.service || null,
        nosave: urlData?.nosave || null,
        help_url: urlData?.help_url || null,
        system,
        defaultRating: latest?.rating ? latest?.rating : (urlData?.rating || null),
        rating : latest?.rating || null,
        reason: (latest?.reason === null || latest?.reason === undefined) ? null : latest?.reason,
        delta: 0,
        sending: false,
        answerId: latest?.answerId || null,
        type_selected: null,
        language,
        ticketAnsweredState: ticketAnswered || undefined,
        extraQuestion: latest?.extraQuestion || null,
    });
    const setState = (newState) => {
        _setState({...state, ...newState});
    };

    useEffect(() => {
        if(state.defaultRating && state.ticket && !state.rating){
            setValue('rating', state.defaultRating);
        }
        ( async () => {
            ticketAnswered = await verifyTicket(urlData.i, urlData.ticket)
            _setState({...state, ticketAnsweredState: ticketAnswered});
        }   
        )();
    }, []);

    useEffect(() => {
        if (lastUpdate && canSendAnswer() && !state.sending) {
            sendAnswer();
        }
    }, [lastUpdate]);

    useEffect(() => {
        if(config.title){
            document.title = config.title[state.language];
        }
    }, [config.title])

    function setValue(key, value){
        setState({
            ...state,
            [key]: value
        });
        setLastUpdate(new Date());
    }

    function setValues(values){
        setState({
            ...state,
            ...values
        });
        setLastUpdate(new Date());
    }

    function canSendAnswer(){
        const { domain, teller, typeId, rating } = state;
        return (domain && teller && typeId) && !didAlreadyAnswer && rating !== null;
    }

    function sendAnswer(){
        if (state.nosave){
            console.log("Dont save the record")
            return 1
        }
        setState({ ...state, sending: true })
        let url = `${HOST_PREFIX}${state.domain}${HOST_SUFFIX}api/survey/device/${state.teller}/answer2/`

        // ================== Used for local testing ===============================
        // let url = `http://127.0.0.1:8000/api/survey/device/${state.teller}/answer2/`
        // ========================================================================

        if (config.single_schema){
            url = `${NEW_HOST_URL}api/df/device/${state.teller}/answer`
        }

        if (config.single_schema_staging){
            url = `${STAGING_HOST_URL}api/df/device/${state.teller}/answer`
        }
        
        fetch( url , { 
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(state),
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            console.log(json)
            if (config.single_schema){
                json = json.data
            }
           
                setState({answerId: json.id, sending: false})
                
                setLatest({
                    ticket: state.ticket,
                    rating: state.rating,
                    reason: state.reason,
                    openAnswer: state.openAnswer,
                    timestamp: String(new Date()),
                    answerId: json.id,
                    extraQuestion: state.extraQuestion
                });
            
        }).catch((er) => {
            console.log(er)
            setState({sending: false})
        });
    }

    return {
        config,
        state,
        type,
        setState,
        setValue,
        setValues,
        openButton,
        didAlreadyAnswer,
        browser,
        loadingConfig,
        hideLanguageBar: language === urlData.lng,
        languages,
        urlData
    };
}


const maybeParseJSON = text => {
    try {
        return JSON.parse(text);
    } catch {
        return undefined;
    }
}

export default App;
